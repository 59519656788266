import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import ContentElement from 'components/ContentElement';
import { SliderVariant } from 'components/Slider';
import Carousel from 'containers/Carousel';
import VideoSection from 'containers/VideoSection';

import './Article.scss';

const ArticleDetailPage: FC<ArticleDetailPageTypes.ArticleDetailProps> = ({
  data: {
    articleDetail: {
      urls,
      seo,
      banner,
      articleContent,
      internalNavHeading,
      videoSection,
      productCarousel,
      articleTableOfContents,
      contentAnchorIds,
      contentNavigationItems,
    },
    allProductDetail: { productsList },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Helmet htmlAttributes={{ class: 'smoth-scroll' }} />
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <div className="article__links">
        <Container gutter className="article__content">
          <Typography
            as="h2"
            color="sherwood-green"
            size={{ base: 25, md: 36 }}
            weight="bold"
            padding={{ top: 'md' }}
          >
            {articleTableOfContents}
          </Typography>
          <ol className="article__links-list">
            {contentNavigationItems.map((heading, index) => (
              <li key={heading}>
                <a href={`#${contentAnchorIds[index]}`} className="article__link">
                  <Typography dangerouslySetInnerHTML={heading} />
                </a>
              </li>
            ))}
          </ol>
        </Container>
      </div>
      <ContentElement showNav contentElement={articleContent} navHeading={internalNavHeading} />
      {videoSection ? <VideoSection {...{ videoSection }} /> : null}
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={SliderVariant.PRODUCTS}
          carousel={productCarousel}
          sliderId="articlesId"
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $productsUrls: [String]) {
    articleDetail(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      articleTableOfContents
      contentAnchorIds
      contentNavigationItems
      banner {
        ...BannerFragment
      }
      internalNavHeading
      articleContent {
        ...ContentElementFragment
      }
      videoSection {
        ...VideoSectionFragment
      }
      productCarousel {
        ...CarouselFragment
      }
    }
    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ArticleDetailPage;
